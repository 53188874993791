import React from "react";
import "./MainNavbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const MainNavbar = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();

  const onClickMainCategory = (value) => {
    Navigate(`/listing-page?main_category=${value}`);
  };

  return (
    <div className="navbar navbar-hover navbar-expand-lg navbar-soft navbar-transparent">
      <nav className="main-navbar collapse navbar-collapse " id="main_nav99">
        <ul className="navbar-nav mx-auto ">
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/listing-page"
              data-toggle="dropdown"
            >
              {t("generalSections.navBar.units")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle" data-toggle="dropdown">
              <span className="mx-1">{t("generalSections.navBar.sale")}</span>
            </span>
            <ul className="dropdown-menu animate fade-up">
              <li>
                <span
                  onClick={() => onClickMainCategory("Residential")}
                  id="Residential"
                  className="dropdown-item icon-arrow"
                >
                  {t("generalSections.navBar.residential")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => onClickMainCategory("Administrative")}
                  id="Administrative"
                  className="dropdown-item icon-arrow"
                >
                  {t("generalSections.navBar.administrative")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => onClickMainCategory("Commercial")}
                  id="Commercial"
                  className="dropdown-item icon-arrow"
                >
                  {t("generalSections.navBar.Commercial")}
                </span>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown">
            <span
              className="nav-link dropdown-toggle mx-1"
              data-toggle="dropdown"
            >
              <span className="mx-1">{t("generalSections.navBar.rent")}</span>
            </span>
            <ul className="dropdown-menu animate fade-up">
              <li>
                <span
                  onClick={() => onClickMainCategory("Residential")}
                  id="Residential"
                  className="dropdown-item icon-arrow"
                >
                  {t("generalSections.navBar.residential")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => onClickMainCategory("Administrative")}
                  id="Administrative"
                  className="dropdown-item icon-arrow"
                >
                  {t("generalSections.navBar.administrative")}
                </span>
              </li>
              <li>
                <span
                  onClick={() => onClickMainCategory("Commercial")}
                  id="Commercial"
                  className="dropdown-item icon-arrow"
                >
                  {t("generalSections.navBar.Commercial")}
                </span>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" to="/installment">
              {t("generalSections.navBar.installment")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/lands"
              data-toggle="dropdown"
            >
              {t("generalSections.navBar.lands")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="/services-category-page"
              data-toggle="dropdown"
            >
              {t("generalSections.navBar.services")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link icon-arrow" to="/about-us-page">
              {t("generalSections.navBar.aboutUs")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link icon-arrow" to="/contact-us-page">
              {t("generalSections.navBar.contactUs")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MainNavbar;
