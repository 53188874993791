import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import { Routes, Route } from "react-router-dom";
import SingleDetailsPage from "./pages/Unit/SingleDetailsPage/SingleDetailsPage";
import ListingPage from "./pages/Unit/ListingPage/ListingPage";
import LoginPage from "./pages/AuthPages/LoginPage/LoginPage";
import SignUpPage from "./pages/AuthPages/SignUpPage/SignUpPage";
import ForgetPassPage from "./pages/AuthPages/ForgetPassPage/ForgetPassPage";
import VerifyPassCodePage from "./pages/AuthPages/VerifyPassCodePage/VerifyPassCodePage";
import NewPassPage from "./pages/AuthPages/NewPassPage/NewPassPage";
import AgencyPage from "./pages/Agency/AgencyPage/AgencyPage";
import AgencyDetailsPage from "./pages/Agency/AgencyDetailsPage/AgencyDetailsPage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import NewsPage from "./pages/News/NewsPage/NewsPage";
import NewsDetailsPage from "./pages/News/NewsDetailsPage/NewsDetailsPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import AgentsPage from "./pages/Agent/AgentsPage/AgentsPage";
import AgentDetailsPage from "./pages/Agent/AgentDetailsPage/AgentDetailsPage";
import UserProfilePage from "./pages/User/UserProfilePage/UserProfilePage";
import UserSettingPage from "./pages/User/UserSettingPage/UserSettingPage";
import UserWishlistPage from "./pages/User/UserWishlistPage/UserWishlistPage";
import UserListPage from "./pages/User/UserListPage/UserListPage";
import UserNotificationsPage from "./pages/User/UserNotificationsPage/UserNotificationsPage";
import { ToastContainer } from "react-toastify";
import PublisherSignUpPage from "./pages/AuthPages/PublisherSignUpPage/PublisherSignUpPage";
import UserAddUnitPage from "./pages/User/UserAddUnitPage/UserAddUnitPage";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useEffect } from "react";
import i18n from "./i18n/i18n";
import ServicesCategoryPage from "./pages/Services/ServicesCategoryPage/ServicesCategoryPage";
import ServiceDetailsPage from "./pages/Services/ServiceDetailsPage/ServiceDetailsPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute ";
import ProtectRoutsHook from "./CustomHooks/Auth/ProtectRoutsHook";
import ServiceListPage from "./pages/Services/ServiceListPage/ServiceListPage";
import ClearLocalStorageOnReload from "./components/ClearLocalStorageOnReload/ClearLocalStorageOnReload";
import OurInfoHook from "./CustomHooks/OurSettings/OurInfo/OurInfoHook";
import PremiumPage from "./pages/PremiumPage/PremiumPage";
import Sidebar from "./components/Utility/Sidebar/Sidebar";
import InstallmentUnitsPage from "./pages/Unit/installment_units_page/InstallmentUnitsPage";
import LandsPage from "./pages/Unit/Lands_page/LandsPage";
import OffersPage from "./pages/Offers/OffersPage";
import OfferDetailsPage from "./pages/Offers/OfferDetailsPage";
function App() {
  const [,] = OurInfoHook();
  const { t, i18n } = useTranslation();
  // protect routs hook
  const [, isUser] = ProtectRoutsHook();
  const lng = cookies.get("i18next") || "ar";
  useEffect(() => {
    window.document.dir = i18n?.dir();
  }, [lng]);
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
      console.error = function () {};
      console.debug = function () {};
      console.warn = function () {};
      console.info = function () {};
    }
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route element={<PrivateRoute auth={isUser} />}>
          <Route path="/user-profile-page" element={<UserProfilePage />} />
          <Route
            path="/user-notifications-page"
            element={<UserNotificationsPage />}
          />
          <Route path="/user-setting-page" element={<UserSettingPage />} />
          <Route path="/user-list-page" element={<UserListPage />} />
          <Route path="/user-add-unit-page" element={<UserAddUnitPage />} />
          <Route path="/user-wishlist-page" element={<UserWishlistPage />} />
          <Route path="/forget-pass-page" element={<ForgetPassPage />} />
          <Route
            path="/verify-pass-code-page"
            element={<VerifyPassCodePage />}
          />
          <Route path="/new-pass-page" element={<NewPassPage />} />
        </Route>
        {/*Start Auth pages*/}
        <Route path="/sign-up-page" element={<SignUpPage />} />
        <Route
          path="/publisher-sign-up-page"
          element={<PublisherSignUpPage />}
        />
        <Route path="/login-page" element={<LoginPage />} />
        {/* End Auth pages*/}
        <Route path="/" element={<HomePage />} />
        <Route path="/error-page" element={<ErrorPage />} />
        <Route
          path="/single-details-page/:id"
          element={<SingleDetailsPage />}
        />
        <Route path="/listing-page" element={<ListingPage />} />
        <Route path="/installment" element={<InstallmentUnitsPage />} />
        <Route path="/lands" element={<LandsPage />} />
        <Route path="/offers" element={<OffersPage />} />
        <Route path="/offer-details/:id" element={<OfferDetailsPage />} />
        <Route path="/premium-page" element={<PremiumPage />} />
        <Route path="/agency-page" element={<AgencyPage />} />
        <Route
          path="/agency-details-page/:id"
          element={<AgencyDetailsPage />}
        />
        <Route
          path="/services-category-page"
          element={<ServicesCategoryPage />}
        />
        <Route
          path="/service-details-page/:id"
          element={<ServiceDetailsPage />}
        />
        <Route path="/service-list-page/:id" element={<ServiceListPage />} />
        <Route path="/contact-us-page" element={<ContactUsPage />} />
        <Route path="/about-us-page" element={<AboutUsPage />} />
        <Route path="/news-page" element={<NewsPage />} />
        <Route path="/news-details-page/:id" element={<NewsDetailsPage />} />
        <Route path="/agents-page" element={<AgentsPage />} />
        <Route path="/agent-details-page/:id" element={<AgentDetailsPage />} />
        {/*Start error page */}
        <Route path="*" exact={true} element={<ErrorPage />} />
        {/*End error page */}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition="Bounce"
      />
      <Sidebar />
      <ClearLocalStorageOnReload />
    </div>
  );
}

export default App;
