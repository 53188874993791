import React from "react";
import { useTranslation } from "react-i18next";
import WantToSell from "../../components/Home/WantToSell/WantToSell";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DisplayWordpadContent from "../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import ContactButtons from "../../components/UI/contact-btns/ContactButtons";
import GetOfferDetailsHook from "../../CustomHooks/Offers/GetOfferDetailsHook";
import { useParams } from "react-router-dom";
import HomeSectionSkeleton from "../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import Cookies from "js-cookie";

const OfferDetailsPage = () => {
  const { t } = useTranslation();
  const lang = Cookies.get("i18next");
  const { id } = useParams();
  const [loading, offerDetails] = GetOfferDetailsHook(id);

  return (
    <div className="listing-page page">
      <CustomHelmet
        title={t("offer_details.breadcrumbTitle")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <InternalHeader offersActive={"offersActive"} />
      <BreadcrumbSection title={t("offer_details.breadcrumbTitle")} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {loading === false ? (
                <>
                  <div className="details-head">
                    <img
                      src={offerDetails?.image}
                      alt="offer-img"
                      loading="lazy"
                      className="w-100 radius-10 h-500px"
                    />
                    <div className="p-3 radius-10 border border-2 txt-start my-3">
                      <h5 className="fw-bold m-0">
                        {lang === "en"
                          ? offerDetails?.data_trans
                            ? offerDetails?.data_trans[0]?.title_en
                            : null
                          : offerDetails?.data_trans
                          ? offerDetails?.data_trans[1]?.title_ar
                          : null}
                      </h5>
                      <p className="location text-capitalize my-2 text-secondary fs-16 fw-medium">
                        <span className=" text-secondary">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        <span className="mx-2">{offerDetails?.address}</span>
                      </p>
                    </div>
                  </div>
                  <div className="details-body txt-start p-4">
                    <h5 className="fw-bold my-3 text-capitalize">
                      {t("offer_details.property_details")}{" "}
                    </h5>
                    {offerDetails?.data_trans ? (
                      lang === "en" ? (
                        <DisplayWordpadContent
                          content={offerDetails?.data_trans[0]?.description_en}
                        />
                      ) : (
                        <DisplayWordpadContent
                          content={offerDetails?.data_trans[1]?.description_ar}
                        />
                      )
                    ) : null}
                  </div>
                </>
              ) : (
                <HomeSectionSkeleton />
              )}
            </div>
            <div className="col-lg-4">
              <ContactButtons
                whatsapp={offerDetails?.user?.phone}
                phone={offerDetails?.user?.phone}
              />
            </div>
          </div>
        </div>
        <div className="my-5">
          <WantToSell />
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default OfferDetailsPage;
