import React from "react";
import "./SearchBox.css";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import SelectComponent from "../../../Utility/SelectComponent/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchBoxHook from "../../../../CustomHooks/SearchBox/SearchBoxHook";
import { Link } from "react-router-dom";
const SearchBox = () => {
  const [
    categoryList,
    active,
    roomsList,
    bathRoomsList,
    gatesOptions,
    ,
    onChangeBathrooms,
    ,
    onChangeRooms,
    onChangeCategoryId,
    onSearch,
    ,
    ,
    handleChangeActive,
    t,
    ,
    ,
    ,
    ,
    ,
    ,
    DistrictsOptions,
    onChangeGateId,
    onChangeDistrictId,
  ] = SearchBoxHook();
  return (
    <div className="search-box">
      <div className="wrap__intro">
        <div className="container">
          <div className="search-content-wrapper align-items-center justify-content-start flex-wrap">
            <div className="mx-auto">
              <div className="wrap__intro-heading">
                <div className="intro-title">
                  <h1 className="des text-capitalize second-title">
                    {t("generalSections.landing.searchBox.findYourDreamHouse")}
                  </h1>
                </div>
                <p>{t("generalSections.landing.searchBox.description")}</p>
                <div className="bg__overlay-black p-4">
                  <div className="search__property">
                    <div className="position-relative">
                      <ul
                        className="nav nav-tabs nav-tabs-02 mb-3 justify-content-start"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li
                          className="nav-item mr-1 curser-pointer"
                          onClick={() => handleChangeActive("buy")}
                        >
                          <span
                            role="button"
                            className={
                              active === "buy" ? "nav-link active" : "nav-link"
                            }
                          >
                            {t("generalSections.landing.searchBox.sale")}
                          </span>
                        </li>
                        <li
                          className="nav-item mr-1 curser-pointer"
                          onClick={() => handleChangeActive("rent")}
                        >
                          <span
                            role="button"
                            className={
                              active === "rent"
                                ? "nav-link active"
                                : "nav-link pointer"
                            }
                          >
                            {t("generalSections.landing.searchBox.rent")}
                          </span>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className={
                            active === "buy"
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }
                          id="buy"
                          role="tabpanel"
                          aria-labelledby="buy-tab"
                        >
                          <div className="search__container text-start">
                            <div className="row input-group no-gutters select-lists">
                              <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={categoryList[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.category"
                                  )}
                                  onChange={onChangeCategoryId}
                                />
                              </div>
                              <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={roomsList}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.rooms"
                                  )}
                                  onChange={onChangeRooms}
                                />
                              </div>
                              <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={bathRoomsList}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.bathRooms"
                                  )}
                                  onChange={onChangeBathrooms}
                                />
                              </div>
                              <div className="col-lg-4 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={gatesOptions[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.gate"
                                  )}
                                  onChange={onChangeGateId}
                                />
                              </div>
                              {/* <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={DistrictsOptions[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.District"
                                  )}
                                  onChange={onChangeDistrictId}
                                />
                              </div> */}
                              <div className="col-lg-2 p-0 input-group-append txt-start">
                                <div
                                  className="btn-box search-btn"
                                  onClick={onSearch}
                                >
                                  <BtnComponent
                                    type={"submit"}
                                    value={t(
                                      "generalSections.landing.searchBox.search"
                                    )}
                                    icon={<FontAwesomeIcon icon={faSearch} />}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            active === "rent"
                              ? "tab-pane fade active show"
                              : "tab-pane fade"
                          }
                          id="rent"
                          role="tabpanel"
                          aria-labelledby="rent-tab"
                        >
                          <div className=" search__container text-start">
                            <div className="row input-group no-gutters select-lists">
                              <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={categoryList[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.category"
                                  )}
                                  onChange={onChangeCategoryId}
                                />
                              </div>
                              <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={roomsList}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.rooms"
                                  )}
                                  onChange={onChangeRooms}
                                  value={{
                                    value: localStorage.getItem("rooms"),
                                    label:
                                      localStorage.getItem("rooms") ||
                                      t(
                                        "generalSections.landing.searchBox.rooms"
                                      ),
                                  }}
                                />
                              </div>
                              <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={bathRoomsList}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.bathRooms"
                                  )}
                                  onChange={onChangeBathrooms}
                                  value={{
                                    value: localStorage.getItem("bathRooms"),
                                    label:
                                      localStorage.getItem("bathRooms") ||
                                      t(
                                        "generalSections.landing.searchBox.bathRooms"
                                      ),
                                  }}
                                />
                              </div>
                              <div className="col-lg-4 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={gatesOptions[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.gate"
                                  )}
                                />
                              </div>
                              {/* <div className="col-lg-2 p-0 select-list-item txt-start">
                                <SelectComponent
                                  options={DistrictsOptions[0]}
                                  placeholder={t(
                                    "generalSections.landing.searchBox.District"
                                  )}
                                  onChange={onChangeDistrictId}
                                />
                              </div> */}
                              <div className="col-lg-2 p-0 input-group-append txt-start">
                                <div
                                  className="btn-box search-btn"
                                  onClick={onSearch}
                                >
                                  <BtnComponent
                                    type={"submit"}
                                    value={t(
                                      "generalSections.landing.searchBox.search"
                                    )}
                                    icon={<FontAwesomeIcon icon={faSearch} />}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <nav className="text-center my-5">
                <ul className="d-flex align-items-center justify-content-between flex-wrap p-3">
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2">
                    <Link
                      to={"/listing-page"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      وحدات
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2">
                    <Link
                      to={"/installment"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      اقساط
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2">
                    <Link
                      to={"/offers"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      عروض
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2">
                    <Link
                      to={"/listing-page"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      تمويل عقارى
                    </Link>
                  </li>
                  <li className="text-white rounded pointer fs-20 fw-medium border border-2 border-white transition my-2">
                    <Link
                      to={"/lands"}
                      className="text-white w-100 h-100 d-block p-3"
                    >
                      اراضى
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
