import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
import AddSearchParamsHook from "../helpers/add_search_params_hook";
const GetAllUnitsHook = () => {
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitsCount, setUnitsCount] = useState(1);
  const firstRender = useRef(true);
  const res = useSelector((state) => state.rootReducer.unitsReducer.allUnits);
  const dispatch = useDispatch();

  const getData = async (queryString) => {
    setLoading(true);
    await dispatch(GetAllUnits(queryString));
    setLoading(false);
  };

  // set search params function
  const [add_search_params, searchParams] = AddSearchParamsHook();

  const category = searchParams.get("category") || "";
  const searchWord = searchParams.get("search") || "";
  const minSpace = searchParams.get("min_space") || "";
  const maxSpace = searchParams.get("max_space") || "";
  const minPrice = searchParams.get("min_price") || "";
  const maxPrice = searchParams.get("max_price") || "";
  const type = searchParams.get("type") || "";
  const rooms = searchParams.get("rooms") || "";
  const bathrooms = searchParams.get("bathrooms") || "";
  const propertyId = searchParams.get("property") || "";
  const finishingValue = searchParams?.get("finishing") || "";
  const finishing = finishingValue.replace(/\+/g, " ");
  const furnished = searchParams?.get("furnished") || "";
  const sort = searchParams.get("sort") || "";
  const districtId = searchParams.get("district") || "";
  const gateId = searchParams.get("gate") || "";
  const mainCategory = searchParams.get("main_category") || "";
  const paying_url = searchParams.get("paying") || "";
  const delivery_date_url = searchParams.get("delivery_date") || "";
  const delivery_start_date_url = searchParams.get("delivery_start_date") || "";
  const delivery_end_date_url = searchParams.get("delivery_end_date") || "";
  const years_url = searchParams.get("years") || "";
  const down_payment_url = searchParams.get("down_payment") || "";
  const fawry_url = searchParams.get("fawry") || "";
  const pageUrl = searchParams.get("page") || "";

  useEffect(() => {
    if (firstRender.current === true) {
      getData(`filter_search=${searchWord}&price_min=${minPrice}&price_max=${maxPrice}&Finishing_type=${finishing}&
      furnished=${furnished}&type=${type}&rooms=${rooms}&bathroom=${bathrooms}&property_id=${propertyId}
      &category_id=${category}&sort=${sort}&main_category=${mainCategory}&gates_id=${gateId}
      &district_id=${districtId}&page=${pageUrl}&paying=${paying_url}&size_min=${minSpace}
      &size_max=${maxSpace}&delivery_date=${delivery_date_url}&
      delivery_start_date=${delivery_start_date_url}&delivery_end_date=${delivery_end_date_url}&
      years=${years_url}&fawry=${fawry_url}&down_payment=${down_payment_url}`);
      firstRender.current = false;
    }
  }, []);

  //on press function for pagination
  const onPressPaginate = (page) => {
    add_search_params("page", page);
    getData(`filter_search=${searchWord}&price_min=${minPrice}&price_max=${maxPrice}&Finishing_type=${finishing}&
      furnished=${furnished}&type=${type}&rooms=${rooms}&bathroom=${bathrooms}&property_id=${propertyId}
      &category_id=${category}&sort=${sort}&main_category=${mainCategory}&gates_id=${gateId}
      &district_id=${districtId}&page=${pageUrl}&paying=${paying_url}&size_min=${minSpace}
      &size_max=${maxSpace}&delivery_date=${delivery_date_url}&
      delivery_start_date=${delivery_start_date_url}&delivery_end_date=${delivery_end_date_url}&
      years=${years_url}&fawry=${fawry_url}&down_payment=${down_payment_url}`);
  };

  useEffect(() => {
    try {
      if (loading === false) {
        if (res?.status === 200) {
          setUnits(res?.data?.data);
          setUnitsCount(res?.data?.data?.length || 0);
          setMeta(res?.data?.meta);
        } else {
          setUnits([]);
          setUnitsCount(0);
          setMeta(null);
        }
      }
    } catch (e) {}
  }, [loading, res]);

  return [loading, units, unitsCount, meta, getData, onPressPaginate];
};

export default GetAllUnitsHook;
