import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllLands } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
import AddSearchParamsHook from "../helpers/add_search_params_hook";

const GetAllLandsHook = () => {
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitsCount, setUnitsCount] = useState(1);
  const firstRender = useRef(true);
  const res = useSelector((state) => state.rootReducer.unitsReducer.lands);
  const dispatch = useDispatch();

  const getData = async (queryString) => {
    setLoading(true);
    await dispatch(GetAllLands(queryString));
    setLoading(false);
  };

  // set search params function
  const [add_search_params, searchParams] = AddSearchParamsHook();

  const minPrice = searchParams.get("price_min") || "";
  const maxPrice = searchParams.get("price_max") || "";
  const pageUrl = searchParams.get("page") || "";

  useEffect(() => {
    if (firstRender.current === true) {
      getData(`price_min=${minPrice}&price_max=${maxPrice}&page=${pageUrl}`);
      firstRender.current = false;
    }
  }, []);

  //on press function for pagination
  const onPressPaginate = (page) => {
    add_search_params("page", page);
    getData(`price_min=${minPrice}&price_max=${maxPrice}&page=${pageUrl}`);
  };

  useEffect(() => {
    try {
      if (loading === false) {
        if (res?.status === 200) {
          setUnits(res?.data?.data);
          setUnitsCount(res?.data?.data?.length || 0);
          setMeta(res?.data?.meta);
        } else {
          setUnits([]);
          setUnitsCount(0);
          setMeta(null);
        }
      }
    } catch (e) {}
  }, [loading, res]);

  return [loading, units, unitsCount, meta, getData, onPressPaginate];
};

export default GetAllLandsHook;
