import React from "react";
import Footer from "../../../components/GeneralSections/Footer/Footer";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import BreadcrumbSection from "../../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import GetServiceDetailsHook from "../../../CustomHooks/Services/GetServiceDetailsHook";
import SlideShow from "../../../components/SingleDetails/SlideShow/SlideShow";
import HomeSectionSkeleton from "../../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import ServiceDetailsInfo from "../../../components/Services/ServiceDetailsInfo/ServiceDetailsInfo";
import DisplayWordpadContent from "../../../components/Utility/DisplayWordpadContent/DisplayWordpadContent";
import ServiceDetailsSidebar from "../../../components/Services/ServiceDetailsSidebar/ServiceDetailsSidebar";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
const ServiceDetailsPage = () => { 
  const { id } = useParams();
  const [loading,serviceDetails] = GetServiceDetailsHook(id?.slice(1,id.length));
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="service-details-page agency-details-page page">
      <CustomHelmet
      title={t("servicePage.serviceDetails")}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader servicesActive={"servicesActive"}/>
      <BreadcrumbSection title= {t("servicePage.serviceDetails")} />
      <section className="profile__agents">
        <div className="container">
          <div className="row min-vh-100">
            <div className="col">
              <div className="row no-gutters">
                <div className="col-lg-12 cards mt-0">
                  {
                    loading===false?
                    <SlideShow unitDetails={serviceDetails?.data}/>
                    :
                    <HomeSectionSkeleton/>
                  }
                </div>
                <div className="col-lg-8">
                <h3 className="text-capitalize detail-heading fw-bold color-primary txt-start">
                {t("servicePage.serviceDetails")}
                </h3>
                <div className="service-features my-4">
                  <ServiceDetailsInfo serviceDetails={serviceDetails} />
                </div>
              <div className="single__detail-features description tabs__custom">
                  <h4 className="text-capitalize fw-bold color-primary">{t("servicePage.des")}</h4>
                  <div className="single__detail-desc">
                        {
                          lang==="en"?
                          <DisplayWordpadContent content={serviceDetails?.data?.data_trans? serviceDetails?.data?.data_trans[1]?.description_en: null} />
                          :
                          <DisplayWordpadContent content={serviceDetails?.data?.data_trans?serviceDetails?.data?.data_trans[0]?.description_ar:null} />
                        }
                  </div>
              </div>
                </div>
                <div className="col-lg-4">
                    <ServiceDetailsSidebar item={serviceDetails?.data}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default ServiceDetailsPage;
