import React from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faAnglesLeft,
  faAnglesRight,
  faMapMarkerAlt,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import OurInfoHook from "../../../CustomHooks/OurSettings/OurInfo/OurInfoHook";
import GetAllGatesHook from "../../../CustomHooks/Gates/GetAllGatesHook";
import Cookies from "js-cookie";
import GetAllCategoriesHook from "../../../CustomHooks/Categories/GetAllCategoriesHook";
import GetAllDistrictsHook from "../../../CustomHooks/Districts/GetAllDistrictsHook";
const Footer = () => {
  const lang = Cookies.get("i18next");
  const [t] = useTranslation();
  const [data] = OurInfoHook();
  const [gatesLoading, gates] = GetAllGatesHook();
  const [categories_loading, categories] = GetAllCategoriesHook();
  const [districtsLoading, districts] = GetAllDistrictsHook();
  const Navigate = useNavigate();

  const onClickCategory = (id) => {
    Navigate(`/listing-page?category=${id}`);
  };
  const onClickGate = (id) => {
    Navigate(`/listing-page?gate=${id}`);
  };
  const onClickDistrict = (id) => {
    Navigate(`/listing-page?district=${id}`);
  };
  return (
    <footer>
      <div className="wrapper__footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3 my-2 px-2">
              <div className="widget__footer">
                <figure>
                  <img
                    src={data?.logo_web}
                    alt="company-logo"
                    className="logo-footer"
                    loading="lazy"
                  />
                </figure>
                <ul className="list-row">
                  <li className="list-row-item">
                    <a
                      href={data?.facebook || "facebook.com"}
                      className="btn-social facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.twitter || "twitter.com"}
                      className="btn-social twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.linkedin || "linkedin.com"}
                      className="btn-social linkedin"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.instagram || "instagram.com"}
                      className="btn-social instagram "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li className="list-row-item">
                    <a
                      href={data?.youtube || "youtube.com"}
                      className="btn-social youtube"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
                <ul className="list-unstyled mb-0 mt-3">
                  <li className="d-flex align-items-center">
                    <b className="mx-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </b>
                    <span>
                      {data?.address ||
                        t("generalSections.footer.col_1.notAvailable")}
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <b className="mx-1">
                      <FontAwesomeIcon icon={faPhoneSquare} />
                    </b>
                    <span>
                      {data?.phone ||
                        t("generalSections.footer.col_1.notAvailable")}
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <b className="mx-1">
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </b>
                    <span>
                      {data?.phone ||
                        t("generalSections.footer.col_1.notAvailable")}
                    </span>
                  </li>
                  <li className="d-flex align-items-center">
                    <b className="mx-1">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </b>
                    <span>
                      {data?.email ||
                        t("generalSections.footer.col_1.notAvailable")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-5 my-2 px-2">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="widget__footer">
                  <h4 className="footer-title fw-bold">
                    {t("generalSections.footer.col_2.quickLinks.categories")}
                  </h4>
                  <div className="link__category-two-column">
                    <ul className="list-unstyled">
                      {categories_loading === false
                        ? categories?.map((item) => {
                            return (
                              <li
                                onClick={() => onClickCategory(item?.id)}
                                className="list-inline-item m-0 my-2 pointer position-relative transition text-capitalize"
                                key={item?.id}
                              >
                                <span className="mx-1">
                                  <FontAwesomeIcon
                                    icon={
                                      lang === "en"
                                        ? faAnglesRight
                                        : faAnglesLeft
                                    }
                                  />
                                </span>
                                <span>
                                  {lang === "en"
                                    ? item?.data_trans
                                      ? item?.data_trans[1]?.title_en
                                      : null
                                    : item?.data_trans
                                    ? item?.data_trans[0]?.title_ar
                                    : null}
                                </span>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </div>
                <div className="widget__footer">
                  <h4 className="footer-title fw-bold">
                    {t("generalSections.footer.col_2.quickLinks.gates")}
                  </h4>
                  <ul className="list-unstyled">
                    {gatesLoading === false
                      ? gates?.map((item) => {
                          return (
                            <li
                              onClick={() => onClickGate(item?.id)}
                              className="list-inline-item m-0 my-2 pointer position-relative transition"
                              key={item?.id}
                            >
                              <span className="mx-1">
                                <FontAwesomeIcon
                                  icon={
                                    lang === "en" ? faAnglesRight : faAnglesLeft
                                  }
                                />
                              </span>
                              <span>
                                {lang === "en"
                                  ? item?.data_trans
                                    ? item?.data_trans[0]?.title_en
                                    : null
                                  : item?.data_trans
                                  ? item?.data_trans[1]?.title_ar
                                  : null}
                              </span>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 my-2 px-5">
              <div className="widget__footer">
                <h4 className="footer-title fw-bold">
                  {t("generalSections.footer.col_2.quickLinks.districts")}
                </h4>
                <div className="link__category-two-column">
                  <ul className="list-unstyled">
                    {districtsLoading === false
                      ? districts?.map((item) => {
                          return (
                            <li
                              onClick={() => onClickDistrict(item?.id)}
                              className="list-inline-item m-0 my-2 pointer position-relative transition text-capitalize"
                              key={item?.id}
                            >
                              <span className="mx-1">
                                <FontAwesomeIcon
                                  icon={
                                    lang === "en" ? faAnglesRight : faAnglesLeft
                                  }
                                />
                              </span>
                              <span>
                                {lang === "en"
                                  ? item?.data_trans
                                    ? `District  ( ${item?.data_trans[1]?.title_en} )`
                                    : null
                                  : item?.data_trans
                                  ? `المنطقه  ( ${item?.data_trans[0]?.title_ar} )`
                                  : null}
                              </span>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg__footer-bottom-v1">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <span>
                {t("generalSections.footer.footerFooter.history")} -&amp;{" "}
                {t("generalSections.footer.footerFooter.madeBy")}{" "}
                <a
                  href="http://iss-tech.com/"
                  className="mx-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Iss.com
                </a>
              </span>
            </div>
            <div className="col-md-6">
              <ul className="list-inline ">
                <li className="list-inline-item">
                  <Link to="/about-us-page">
                    {t("generalSections.footer.footerFooter.privacy")}
                  </Link>
                </li>
                <li className="list-inline-item mx-2">
                  <Link to="/contact-us-page">
                    {t("generalSections.footer.footerFooter.contact")}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/about-us-page">
                    {t("generalSections.footer.footerFooter.aboutUs")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
