import React from "react";
import "./Cta.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Cta = () => {
  const [t] = useTranslation();
  return (
    <section className="cta mx-auto p-4 container rounded-4 position-relative overflow-hidden ">
      <div className="row align-items-center position-relative">
        <div className="col-lg-9">
          <h2 className="text-uppercase fw-bold text-white">
            <span className="color-primary mx-1"> {t("homePage.cta.offers")} </span>
            {t("homePage.cta.title")}
          </h2>
          <p className="text-capitalize text-white fs-18">
            {t("homePage.cta.des")}
          </p>
        </div>
        <div className="col-lg-3">
          <div className="btn-box mx-2">
            <Link
              to="/premium-page"
              className="request-btn my-2 text-uppercase d-block h-100"
            >
              <h3 className="special-offers fw-bold m-0 text-white bg-pri rounded h-100 text-nowrap">
              {t("homePage.cta.specialOffers")}
              </h3>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
