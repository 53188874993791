import React from "react";
import NotFound from "../Utility/NotFound/NotFound";
import CustomPagination from "../Utility/CustomPagination/CustomPagination";
import OfferCard from "./OfferCard";
import ServiceCardSkeleton from "../Services/ServiceCardSkeleton/ServiceCardSkeleton";
import GetAllOffersHook from "../../CustomHooks/Offers/GetAllOffersHook";

const OffersListContent = () => {
  const [loading, offers, offersCount, meta, onPressPaginate] =
    GetAllOffersHook();
  const offersSkeleton = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <div className="list-content">
      <div className="row units-wrapper">
        {loading === false ? (
          offersCount > 0 ? (
            offers?.map((item) => (
              <div className="col-md-6 col-xl-3" key={item?.id}>
                <OfferCard item={item} />
              </div>
            ))
          ) : (
            <NotFound />
          )
        ) : (
          offersSkeleton.map((_, i) => (
            <div className="col-md-6 col-xl-3" key={i}>
              <ServiceCardSkeleton />
            </div>
          ))
        )}
      </div>
      <div className="pagination-content d-flex align-items-center justify-content-center">
        {offersCount > 1 && (
          <CustomPagination
            totalPages={meta?.last_page}
            currentPage={meta?.current_page}
            onChange={onPressPaginate}
          />
        )}
      </div>
    </div>
  );
};

export default OffersListContent;
