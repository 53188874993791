import { useSearchParams } from "react-router-dom";

const AddSearchParamsHook = () => {
  // set search params function
  const [searchParams, setSearchParams] = useSearchParams();
  const add_search_params = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };
  return [add_search_params, searchParams, setSearchParams];
};

export default AddSearchParamsHook;
