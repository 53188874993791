import { useMemo, useRef, useState } from "react";
import {
  GetAllUnits,
  GetInstallmentUnits,
} from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";
import { useDispatch } from "react-redux";
import { changeFilterSidebarActive } from "../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import { useNavigate } from "react-router-dom";
import AddSearchParamsHook from "../helpers/add_search_params_hook";

const FilterHook = (installment) => {
  const [searchWord, setSearchWord] = useState("");
  const [code, setCode] = useState("");
  const [sort, setSort] = useState("");
  const [minSpace, setMinSpace] = useState("");
  const [maxSpace, setMaxSpace] = useState("");
  const [rooms, setRooms] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [type, setType] = useState("");
  const [property_id, set_property_id] = useState("");
  const [category_id, set_category_id] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [finishing, setFinishing] = useState("");
  const [furnished, setFurnished] = useState("");
  const [gateId, setGateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [paying, set_paying] = useState("");
  const [delivery_date, set_delivery_date] = useState("");
  const [delivery_start_date, set_delivery_start_date] = useState("");
  const [delivery_end_date, set_delivery_end_date] = useState("");
  const [fawry, set_fawry] = useState("");
  const [years, set_years] = useState("");
  const [down_payment, set_down_payment] = useState("");

  const [add_search_params, searchParams, setSearchParams] =
    AddSearchParamsHook();

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const categoryUrl = searchParams?.get("category") || "";
  const searchWordUrl = searchParams?.get("search") || "";
  const minSpaceUrl = searchParams?.get("min_space") || "";
  const maxSpaceUrl = searchParams?.get("max_space") || "";
  const minPriceUrl = searchParams?.get("min_price") || "";
  const maxPriceUrl = searchParams?.get("max_price") || "";
  const typeUrl = searchParams?.get("type") || "";
  const roomsUrl = searchParams?.get("rooms") || "";
  const bathroomsUrl = searchParams?.get("bathrooms") || "";
  const propertyIdUrl = searchParams?.get("property") || "";
  const finishingValue = searchParams?.get("finishing") || "";
  const finishingUrl = finishingValue.replace(/\+/g, " ");
  const furnishedUrl = searchParams?.get("furnished") || "";
  const sortUrl = searchParams?.get("sort") || "";
  const districtIdUrl = searchParams?.get("district") || "";
  const gateIdUrl = searchParams?.get("gate") || "";
  const mainCategoryUrl = searchParams?.get("main_category") || "";
  const paying_url = searchParams.get("paying") || "";
  const delivery_date_url = searchParams.get("delivery_date") || "";
  const delivery_start_date_url = searchParams.get("delivery_start_date") || "";
  const delivery_end_date_url = searchParams.get("delivery_end_date") || "";
  const years_url = searchParams.get("years") || "";
  const down_payment_url = searchParams.get("down_payment") || "";
  const fawry_url = searchParams.get("fawry") || "";
  const pageUrl = searchParams.get("page") || "";

  // get the data from the server
  const getFilterData = async (queryString) => {
    await dispatch(
      installment ? GetInstallmentUnits(queryString) : GetAllUnits(queryString)
    );
  };

  // when the user click to search of specific unit
  const handleChangeFilterSidebarActive = () => {
    dispatch(changeFilterSidebarActive());
  };

  // on search function
  const onSearch = (e) => {
    e.preventDefault();
    Navigate(`/listing-page?rooms=${rooms}&bathrooms=${bathrooms}
      &category=${category_id}&district=${districtId}&gate=${gateId}`);
  };

  // all of query string items
  const queryStringDependencies = [
    searchWordUrl,
    minPriceUrl,
    maxPriceUrl,
    typeUrl,
    roomsUrl,
    bathroomsUrl,
    propertyIdUrl,
    categoryUrl,
    sortUrl,
    mainCategoryUrl,
    gateIdUrl,
    districtIdUrl,
    paying_url,
    delivery_date_url,
    delivery_start_date_url,
    delivery_end_date_url,
    years_url,
    fawry_url,
    down_payment_url,
    finishingUrl,
    furnishedUrl,
  ];

  // store the query string in useMemo to optimize the performance
  const queryString = useMemo(() => {
    const commonQueryString = `filter_search=${searchWordUrl}&price_min=${minPriceUrl}&price_max=${maxPriceUrl}
    &Finishing_type=${finishingUrl}&furnished=${furnishedUrl}&type=${typeUrl}&rooms=${roomsUrl}&bathroom=${bathroomsUrl}
      &property_id=${propertyIdUrl}&category_id=${categoryUrl}&sort=${sortUrl}
      &main_category=${mainCategoryUrl}&gates_id=${gateIdUrl}&district_id=${districtIdUrl}&size_min=${minSpaceUrl}
      &size_max=${maxSpaceUrl}&delivery_date=${delivery_date_url}&
      delivery_start_date=${delivery_start_date_url}&delivery_end_date=${delivery_end_date_url}&
      years=${years_url}&fawry=${fawry_url}&down_payment=${down_payment_url}&page=${pageUrl}`;

    const additionalQueryString = installment ? "" : `&paying=${paying_url}`;
    return commonQueryString + additionalQueryString;
  }, queryStringDependencies);

  // store the prev query sting to avoid unnecessary rerenders
  const prevQueryStringRef = useRef(queryString);

  const onSearchSidebar = () => {
    handleChangeFilterSidebarActive();
    if (prevQueryStringRef.current !== queryString) {
      getFilterData(queryString);
      prevQueryStringRef.current = queryString;
    }
  };

  // function to reset the filter values
  const Reset = () => {
    setSearchWord("");
    setMinPrice("");
    setMaxPrice("");
    setMinSpace("");
    setMaxSpace("");
    setType("");
    setRooms("");
    setBathrooms("");
    set_property_id("");
    set_category_id("");
    setSort("");
    setFurnished("");
    setFinishing("");
    setGateId("");
    setDistrictId("");
    getFilterData();
    setSearchParams({});
  };

  let prevFinishing = useRef("");
  let prevFurnished = useRef("");
  let prevCategoryId = useRef("");
  let prevMainCategory = useRef("");
  let prevRooms = useRef("");
  let prevBathrooms = useRef("");
  let prevMinSpace = useRef("");
  let prevMaxSpace = useRef("");
  let prevMinPrice = useRef("");
  let prevMaxPrice = useRef("");
  let prevType = useRef("");
  let prevPaying = useRef("");
  let prevYears = useRef("");
  let prevDeliveryDate = useRef("");

  // functions that handle various user interactions and updates the corresponding values in the browser's local storage.
  const onChangeCode = (e) => {
    setCode(e.target.value);
    add_search_params("code", e.target.value);
  };

  const onChangeSearchWord = (e) => {
    setSearchWord(e.target.value);
    add_search_params("search", e.target.value);
  };

  const onChangeMinPrice = (e) => {
    if (prevMinPrice.current !== e.target.value) {
      setMinPrice(e.target.value);
      add_search_params("min_price", e.target.value);
      prevMinPrice.current = e.target.value;
    } else {
      setMinPrice("");
      add_search_params("min_price", "");
      prevMinPrice.current = "";
    }
  };

  const onChangeMaxPrice = (e) => {
    if (prevMaxPrice.current !== e.target.value) {
      setMaxPrice(e.target.value);
      add_search_params("max_price", e.target.value);
      prevMaxPrice.current = e.target.value;
    } else {
      setMaxPrice("");
      add_search_params("max_price", "");
      prevMaxPrice.current = "";
    }
  };

  const onChangeSort = (e) => {
    setSort(e.value);
    add_search_params("sort", e.value);
  };

  const onChangeType = (e, val) => {
    // Determine the new value based on the available properties
    const newValue =
      e.value !== undefined
        ? e.value
        : e.target
        ? e.target.id !== undefined
          ? e.target.id
          : e.target.value
        : val;

    // Check if the new value is different from the previous value
    if (prevType.current !== newValue) {
      setType(newValue);
      add_search_params("type", newValue);
      prevType.current = newValue; // Update the prevType ref
    } else {
      // Clear the value if the same option is selected again (optional)
      setType("");
      add_search_params("type", "");
      prevType.current = ""; // Reset the prevType ref
    }
  };

  const onChangeMinSpace = (e) => {
    if (prevMinSpace.current !== e.target.value) {
      setMinSpace(e.target.value);
      add_search_params("min_space", e.target.value);
      prevMinSpace.current = e.target.value;
    } else {
      setMinSpace("");
      add_search_params("min_space", "");
      prevMinSpace.current = "";
    }
  };

  const onChangeMaxSpace = (e) => {
    if (prevMaxSpace.current !== e.target.value) {
      setMaxSpace(e.target.value);
      add_search_params("max_space", e.target.value);
      prevMaxSpace.current = e.target.value;
    } else {
      setMinSpace("");
      add_search_params("max_space", "");
      prevMaxSpace.current = "";
    }
  };

  const onChangeBathrooms = (e) => {
    // Determine the value based on the available property
    const newValue = e.value !== undefined ? e.value : e.target.value;

    // Check if the new value is different from the previous value
    if (prevBathrooms.current !== newValue) {
      setBathrooms(newValue);
      add_search_params("bathrooms", newValue);
      prevBathrooms.current = newValue; // Update the prevRooms ref
    } else {
      // Clear the value if the same option is selected again (optional)
      setBathrooms("");
      add_search_params("bathrooms", "");
      prevBathrooms.current = ""; // Reset the prevRooms ref
    }
  };

  const onChangeRooms = (e) => {
    // Determine the value based on the available property
    const newValue = e.value !== undefined ? e.value : e.target.value;

    // Check if the new value is different from the previous value
    if (prevRooms.current !== newValue) {
      setRooms(newValue);
      add_search_params("rooms", newValue);
      prevRooms.current = newValue; // Update the prevRooms ref
    } else {
      // Clear the value if the same option is selected again (optional)
      setRooms("");
      add_search_params("rooms", "");
      prevRooms.current = ""; // Reset the prevRooms ref
    }
  };

  const onChangePropertyId = (e) => {
    if (e.target.checked === true) {
      set_property_id(e.target.id);
      add_search_params("property", e.target.id);
    } else {
      set_property_id("");
      add_search_params("property", "");
    }
  };

  const onChangeFinishing = (e) => {
    if (prevFinishing.current !== e) {
      setFinishing(e);
      add_search_params("finishing", e);
      prevFinishing.current = e; // update the prevFinishing ref
    } else {
      // Clear the value if the same option is selected again (optional)
      setFinishing("");
      add_search_params("finishing", "");
      prevFinishing.current = "";
    }
  };

  const onChangeFurnished = (e) => {
    if (prevFurnished.current !== e) {
      setFurnished(e);
      add_search_params("furnished", e);
      prevFurnished.current = e;
    } else {
      setFurnished("");
      add_search_params("furnished", "");
      prevFurnished.current = "";
    }
  };

  const onChangeCategoryId = (e) => {
    // Determine the value based on the available property
    const newValue =
      e.id !== undefined ? e.id : e.target ? e.target.id : undefined;

    // Check if the new value is different from the previous value
    if (prevCategoryId.current !== newValue) {
      set_category_id(newValue);
      add_search_params("category", newValue);
      prevCategoryId.current = newValue; // Update the prevCategoryId ref
    } else {
      // Clear the value if the same option is selected again (optional)
      set_category_id("");
      add_search_params("category", "");
      prevCategoryId.current = ""; // Reset the prevCategoryId ref
    }
  };

  const onChangeMainCategory = (e) => {
    const newValue =
      e.id !== undefined ? e.id : e.target ? e.target.id : undefined;
    if (prevMainCategory.current !== newValue) {
      setMainCategory(newValue);
      add_search_params("main_category", newValue);
      prevMainCategory.current = newValue;
    } else {
      setMainCategory("");
      add_search_params("main_category", "");
      prevMainCategory.current = "";
    }
  };

  const onChangeGateId = (e) => {
    if (e.target) {
      setGateId(e.target.id);
      add_search_params("gate", e.target.id);
    } else {
      setGateId(e.id);
      add_search_params("gate", e.id);
    }
  };

  const onChangeDistrictId = (e) => {
    if (e.target) {
      setDistrictId(e.target.id);
      add_search_params("district", e.target.id);
    } else {
      setDistrictId(e.id);
      add_search_params("district", e.id);
    }
  };

  const onChangePaying = (e) => {
    const newValue =
      e?.value !== undefined
        ? e.value
        : e?.target?.id !== undefined
        ? e.target.id
        : e?.target?.value !== undefined
        ? e.target.value
        : "";
    if (prevPaying.current !== newValue) {
      set_paying(newValue);
      add_search_params("paying", newValue);
      prevPaying.current = newValue;
    } else {
      set_paying("");
      add_search_params("paying", "");
      prevPaying.current = "";
    }
  };

  const on_change_delivery_date = (e) => {
    // Determine the new value based on available properties
    const newValue =
      e?.target?.id !== undefined
        ? e.target.id
        : e?.target?.value !== undefined
        ? e.target.value
        : e?.value;

    // Check if the new value is different from the previous value
    if (prevDeliveryDate.current !== newValue) {
      set_delivery_date(newValue);
      add_search_params("delivery_date", newValue);
      prevDeliveryDate.current = newValue; // Update the prevDeliveryDate ref
    } else {
      // Clear the value if the same option is selected again (optional)
      set_delivery_date("");
      add_search_params("delivery_date", "");
      prevDeliveryDate.current = "";
    }
  };

  const on_change_delivery_start_date = (e) => {
    set_delivery_start_date(e.target.value);
    add_search_params("delivery_start_date", e.target.value);
  };

  const on_change_delivery_end_date = (e) => {
    set_delivery_end_date(e.target.value);
    add_search_params("delivery_end_date", e.target.value);
  };

  const on_change_years = (e) => {
    set_years(e.target.value);
    add_search_params("years", e.target.value);

    // Determine the value based on the available property
    const newValue =
      e.target.value !== undefined ? e.target.value : e.target.id;

    // Check if the new value is different from the previous value
    if (prevYears.current !== newValue) {
      set_years(newValue);
      add_search_params("years", newValue);
      prevYears.current = newValue; // Update the prevYears ref
    } else {
      // Clear the value if the same option is selected again (optional)
      set_years("");
      add_search_params("years", "");
      prevYears.current = ""; // Reset the prevRooms ref
    }
  };

  const on_change_fawry = (e) => {
    if (e.target.checked === true) {
      set_fawry(1);
      add_search_params("fawry", 1);
    }
  };

  const on_change_down_payment = (e) => {
    set_down_payment(e.target.value);
    add_search_params("down_payment", e.target.value);
  };

  return [
    minSpace,
    maxSpace,
    rooms,
    bathrooms,
    type,
    searchWord,
    property_id,
    category_id,
    minPrice,
    maxPrice,
    onChangeMinSpace,
    onChangeMaxSpace,
    onChangeBathrooms,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    onSearchSidebar,
    Reset,
    sort,
    onChangeSort,
    onChangeCode,
    code,
    onChangeMainCategory,
    gateId,
    onChangeGateId,
    onChangeFinishing,
    onChangeFurnished,
    onChangeDistrictId,
    paying,
    onChangePaying,
    delivery_date,
    on_change_delivery_date,
    delivery_end_date,
    on_change_delivery_end_date,
    delivery_start_date,
    on_change_delivery_start_date,
    fawry,
    on_change_fawry,
    years,
    on_change_years,
    down_payment,
    on_change_down_payment,
  ];
};

export default FilterHook;
