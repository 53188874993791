import React from "react";
import './NewsDetailsPage.css'
import NewsSidebar from "../../../components/News/NewsSidebar/NewsSidebar";
import home_bg from '../../../images/home-bg.jpg'
import person_img from '../../../images/anonymous.png'
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import Cta from "../../../components/Home/Cta/Cta";
import ScrollToTopBtn from "../../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../../components/GeneralSections/InternalHeader/InternalHeader";
import CustomHelmet from "../../../components/Utility/SEO/CustomHelmet/CustomHelmet";
import { useTranslation } from "react-i18next";
const NewsDetailsPage = () => {
  const [t] = useTranslation()
  return (
    <div className="news-details-page page">
      <CustomHelmet
      title={"News Details"}
      description={t("SEO.meta.description")}
      keywords={t("SEO.meta.keywords")}
      canonical={window.location.href}
      />
      <InternalHeader breadcrumbTitle={"News Details"} newsActive={"newsActive"}/>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single__blog-detail text-start">
                <h1 className="fw-bold">Tips And Tricks To Have Your Dream Home At A Low Cost</h1>
                <div className="single__blog-detail-info">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <figure className="image-profile">
                        <img
                          src={person_img}
                          className="img-fluid"
                          alt=""
                          loading="lazy"
                        />
                      </figure>
                    </li>
                    <li className="list-inline-item">
                      <span>by</span> <a href="/">john doe,</a>
                    </li>
                    <li className="list-inline-item">
                      <span className="text-dark text-capitalize ml-1">
                        descember 09, 2016
                      </span>
                    </li>
                    <li className="list-inline-item">
                      <span className="text-dark text-capitalize ml-1">in</span> <a href="/">business</a>
                    </li>
                  </ul>
                </div>
                <figure>
                  <img
                    src={home_bg}
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </figure>
                <p className="drop-cap">
                  The European languages are members of the same family. Their
                  separate existence is a myth. For science, music, sport, etc,
                  Europe uses the same vocabulary. The languages only differ in
                  their grammar, their pronunciation and their most common
                  words. Everyone realizes why a new common language would be
                  desirable: one could refuse to pay expensive translators.
                </p>
                <p>
                  To achieve this, it would be necessary to have uniform
                  grammar, pronunciation and more common words. If several
                  languages coalesce, the grammar of the resulting language is
                  more simple and regular than that of the individual languages.
                  The new common language will be more simple and regular than
                  the existing European languages. It will be as simple as
                  Occidental; in fact, it will be Occidental. To an English
                  person, it will seem like simplified English, as a skeptical
                  Cambridge friend of mine told me what Occidental is.
                </p>
                <blockquote className="block-quote">
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <cite>Tom Cruise</cite>
                </blockquote>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language ocean. A small river named
                  Duden flows by their place and supplies it with the necessary
                  regelialia.
                </p>
                <div className="blog__tags mb-4">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="/">#property</a>
                    </li>

                    <li className="list-inline-item">
                      <a href="/">#real estate</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="/">#listing</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="/">#rent</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="/">#sell</a>
                    </li>
                  </ul>
                </div>
                <div className="wrap__profile">
                  <div className="wrap__profile-author text-start">
                    <figure>
                      <img
                        src={person_img}
                        alt=""
                        className="img-fluid img-circle"
                        loading="lazy"
                      />
                    </figure>
                    <div className="wrap__profile-author-detail">
                      <div className="wrap__profile-author-detail-name">author</div>
                      <h5>jhon doe</h5>
                      <p>
                        Hi, nice to meet you let me introduce for you, I am Jhon
                        Doe freelancer designer & web programmer from indonesia,
                        base lampung
                      </p>
                      <ul className="list-row">
                        <li className="list-row-item">
                          <a
                            href="/"
                            className="btn-social facebook "
                          >
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li className="list-row-item">
                          <a
                            href="/"
                            className="btn-social twitter "
                          >
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-row-item">
                          <a
                            href="/"
                            className="btn-social instagram "
                          >
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                        <li className="list-row-item">
                          <a
                            href="/"
                            className="btn-social telegram "
                          >
                            <i className="fa fa-telegram"></i>
                          </a>
                        </li>
                        <li className="list-row-item">
                          <a
                            href="/"
                            className="btn-social linkedin "
                          >
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single_navigation-prev">
                      <a href="/">
                        <span>previous post</span>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Rem, similique.
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single_navigation-next text-left text-md-right">
                      <a href="/">
                        <span>next post</span>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Perferendis, nesciunt.
                      </a>
                    </div>
                  </div>
                </div>
                <h6>3 Comments:</h6>
                <div className="single__detail-features-review">
                  <div className="comment-wrapper">
                    <div className="img-wrapper">
                    <img
                      className="img-fluid rounded-circle"
                      src={person_img}
                      alt=""
                      loading="lazy"
                    />
                    </div>
                    <div className="info-wrapper">
                    <h6 className="mt-0">Jhon doe</h6>
                      <span className="mb-3">Mei 13, 2020</span>
                      <ul className="list-row d-flex">
                        <li className="list-row-item">
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="list-row-item">3/5</li>
                      </ul>
                      <p>
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin. Cras purus odio,
                        vestibulum in vulputate at, tempus viverra turpis. Fusce
                        condimentum nunc ac nisi vulputate fringilla. Donec
                        lacinia congue felis in faucibus.
                      </p>
                      <div className="comment-wrapper">
                    <div className="img-wrapper">
                    <img
                      className="img-fluid rounded-circle"
                      src={person_img}
                      alt=""
                      loading="lazy"
                    />
                    </div>
                    <div className="info-wrapper">
                    <h6 className="mt-0">Jhon doe</h6>
                      <span className="mb-3">Mei 13, 2020</span>
                      <ul className="list-row d-flex">
                        <li className="list-row-item">
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="list-row-item">3/5</li>
                      </ul>
                      <p>
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin. Cras purus odio,
                        vestibulum in vulputate at, tempus viverra turpis. Fusce
                        condimentum nunc ac nisi vulputate fringilla. Donec
                        lacinia congue felis in faucibus.
                      </p>
                    </div>
                  </div>
                    </div>
                  </div>
                  <div className="comment-wrapper">
                    <div className="img-wrapper">
                    <img
                      className="img-fluid rounded-circle"
                      src={person_img}
                      alt=""
                      loading="lazy"
                    />
                    </div>
                    <div className="info-wrapper">
                    <h6 className="mt-0">Jhon doe</h6>
                      <span className="mb-3">Mei 13, 2020</span>
                      <ul className="list-row d-flex">
                        <li className="list-row-item">
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="list-row-item">3/5</li>
                      </ul>
                      <p>
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin. Cras purus odio,
                        vestibulum in vulputate at, tempus viverra turpis. Fusce
                        condimentum nunc ac nisi vulputate fringilla. Donec
                        lacinia congue felis in faucibus.
                      </p>
                    </div>
                  </div>
                  <div className="comment-wrapper">
                    <div className="img-wrapper">
                    <img
                      className="img-fluid rounded-circle"
                      src={person_img}
                      alt=""
                      loading="lazy"
                    />
                    </div>
                    <div className="info-wrapper">
                    <h6 className="mt-0">Jhon doe</h6>
                      <span className="mb-3">Mei 13, 2020</span>
                      <ul className="list-row d-flex">
                        <li className="list-row-item">
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="list-row-item">3/5</li>
                      </ul>
                      <p>
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel
                        metus scelerisque ante sollicitudin. Cras purus odio,
                        vestibulum in vulputate at, tempus viverra turpis. Fusce
                        condimentum nunc ac nisi vulputate fringilla. Donec
                        lacinia congue felis in faucibus.
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-12">
                      <p className="mb-2">Your rating for this listing:</p>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star selected"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </li>
                        <li className="list-inline-item">3/5</li>
                      </ul>
                      <div className="form-group">
                        <label>Your Name</label>
                        <input
                          type="text"
                          className="custom-input"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>What's your Email?</label>
                        <input
                          type="email"
                          className="custom-input"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Subject</label>
                        <input
                          type="text"
                          className="custom-input"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Your message</label>
                        <textarea className="custom-input" rows="4"></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="btn-box wd-fit">
                    <BtnComponent type={"submit"} value={"submit review"} icon={<i className="fa fa-paper-plane ml-2"></i>}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <NewsSidebar />
            </div>
          </div>
        </div>
      </section>
      <Cta />
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default NewsDetailsPage;
