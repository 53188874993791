import React from "react";
import './Landing.css'
import SearchBox from "../Landing/SearchBox/SearchBox";
import Header from "../Header/Header";

const Landing = () => {
  return (
    <div className="landing bg-theme">
      <div className="bg-overlay"></div>
      <Header />
      <SearchBox />
    </div>
  );
};

export default Landing;
