import React from "react";
import Card from "../../../components/Utility/Cards/Card/Card";
import CardSkeleton from "../../Utility/Cards/CardSkeleton/CardSkeleton";
import NotFound from "../../Utility/NotFound/NotFound";
import CustomPagination from "../../Utility/CustomPagination/CustomPagination";
import GetAllWishlistHook from "../../../CustomHooks/Wishlist/GetAllWishlistHook";
import GetAllLandsHook from "../../../CustomHooks/UnitsHook/GetAllLandsHook";

const LandsListContent = () => {
  const [, , wishlistIds] = GetAllWishlistHook();

  const [loading, units, unitsCount, meta, , onPressPaginate] =
    GetAllLandsHook();

  const unitsSkeleton = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <div className="list-content">
      <div className="tabs__custom-v2">
        <div className="vertical-cards tab-content active">
          <div
            className="tab-pane fade show active my-4"
            id="pills-tab-two"
            role="tabpanel"
            aria-labelledby="pills-tab-two"
          >
            <div className="row units-wrapper">
              {loading === false ? (
                unitsCount > 0 ? (
                  units?.map((item) => (
                    <div className="col-sm-6 col-lg-4" key={item?.id}>
                      <Card item={item} key={item?.id} favItem={wishlistIds} />
                    </div>
                  ))
                ) : (
                  <NotFound />
                )
              ) : (
                unitsSkeleton.map((_, i) => (
                  <div className="col-sm-6 col-lg-4" key={i}>
                    <CardSkeleton />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="pagination-content d-flex align-items-center justify-content-center">
          {unitsCount > 1 && (
            <CustomPagination
              totalPages={meta?.last_page}
              currentPage={meta?.current_page}
              onChange={onPressPaginate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LandsListContent;
