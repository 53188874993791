import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ShareComponent from "../../Utility/Share/ShareComponent/ShareComponent";
import logo from "../../../images/logo.jpg";

const ContactButtons = ({ whatsapp, phone, email }) => {
  const [t] = useTranslation();
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  return (
    <div className="contacts text-center">
      <div className="border border-2 p-5 radius-10 overflow-hidden">
        <img src={logo} loading="lazy" alt="logo" className="w-100px" />
      </div>
      <div className="p-3 my-2">
        <ul className="list-row my-1 contacts">
          {phone && (
            <li className="list-row-item px-3 py-1 radius-5 border border-1 text-nowrap d-flex align-items-center">
              <a
                href={`tel:+2${phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social twitter radius-50 overflow-hidden"
              >
                <FontAwesomeIcon icon={faPhone} />
              </a>
              <span className="mx-2">
                {t("singleDetailsPage.singleDetailsSidebar.phone")}
              </span>
            </li>
          )}
          {whatsapp && (
            <li className="list-row-item px-3 py-1 radius-5 border border-1">
              <a
                href={`https://wa.me/+2${phone}`}
                target="_blank"
                rel="noreferrer"
                className="btn-social whatsapp radius-50 overflow-hidden"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </li>
          )}
          {email && (
            <li className="list-row-item px-3 py-1 radius-5 border border-1">
              <a
                href={`mailto:${email}`}
                className="btn-social email-btn bg-youtube  radius-50 overflow-hidden"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </li>
          )}
          <li className="list-row-item px-4 py-1 radius-5 border border-1">
            <span
              className="fs-20 pointer"
              onClick={() => handleChangeActive()}
            >
              <FontAwesomeIcon icon={faShareNodes} />
            </span>
          </li>
          {active && (
            <ShareComponent
              handleChange={handleChangeActive}
              shareUrl={window.location.href}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default ContactButtons;
