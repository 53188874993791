import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const ServiceCategoryCard = ({ item }) => {
  const Navigate = useNavigate();
  const lang = Cookies.get("i18next");
  return (
    <div
      className="team-member-card wrap-agent service-card position-relative overflow-hidden"
      onClick={() => Navigate(`/service-list-page/:${item?.id}`)}
    >
      <div className="ribbon text-capitalize">{item?.services_count || 0}</div>
      <div className="team-member">
        <div className="team-img service-img overflow-hidden">
          <img
            alt="team member"
            loading="lazy"
            className="w-100 h-100 transition"
            src={item?.image}
          />
        </div>
        <div className="team-title p-2">
          <h4 className="text-capitalize fw-bold color-primary ellipses">
            {lang === "en"
              ? item?.data_trans
                ? item?.data_trans[0]?.title_en
                : null
              : item?.data_trans
              ? item?.data_trans[1]?.title_ar
              : null}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ServiceCategoryCard;
