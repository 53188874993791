import React, { useState } from "react";
import ListingSidebarHook from "../../../../CustomHooks/Listing/ListingSidebarHook/ListingSidebarHook";
import BtnComponent from "../../../Utility/BtnComponent/BtnComponent";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddSearchParamsHook from "../../../../CustomHooks/helpers/add_search_params_hook";

const FilterItems = () => {
  const [activeBtn, setActiveBtn] = useState("residential");
  const [add_search_params, searchParams, setSearchParams] =
    AddSearchParamsHook();

  const mainCategoryUrl = searchParams?.get("main_category") || "";
  const categoryUrl = searchParams?.get("category") || "";
  const finishingValue = searchParams?.get("finishing") || "";
  const finishingUrl = finishingValue.replace(/\+/g, " ");
  const roomsUrl = searchParams?.get("rooms") || "";
  const bathroomsUrl = searchParams?.get("bathrooms") || "";
  const furnishedUrl = searchParams?.get("furnished") || "";
  const minSpaceUrl = searchParams?.get("min_space") || "";
  const maxSpaceUrl = searchParams?.get("max_space") || "";
  const minPriceUrl = searchParams?.get("min_price") || "";
  const maxPriceUrl = searchParams?.get("max_price") || "";
  const typeUrl = searchParams?.get("type") || "";
  const paying_url = searchParams.get("paying") || "";
  const years_url = searchParams.get("years") || "";
  const delivery_date_url = searchParams.get("delivery_date") || "";

  const [
    t,
    onSearch,
    Reset,
    searchWord,
    onSearchSidebar,
    onChangeSearchWord,
    code,
    onChangeCode,
    gatesOptions,
    typeList,
    onChangeType,
    handleTypeLang,
    categoryList,
    onChangeCategoryId,
    categoryValueResult,
    options_3,
    onChangeMinSpace,
    onChangeMaxSpace,
    minSpace,
    maxSpace,
    options_5,
    onChangeRooms,
    options_6,
    onChangeBathrooms,
    formatNumber,
    minPrice,
    maxPrice,
    value,
    handleChange,
    open,
    handleCollaps,
    showState,
    properties_loading,
    properties,
    lang,
    onChangePropertyId,
    handleChangeActive,
    active,
    categories_loading,
    categories,
    gates,
    gatesLoading,
    onChangeGateId,
    onChangeFinishing,
    onChangeFurnished,
    handleChangeFilterSidebarActive,
    DistrictsOptions,
    onChangeDistrictId,
    onChangePaying,
    paying_list,
    delivery_date,
    on_change_delivery_date,
    delivery_end_date,
    on_change_delivery_end_date,
    delivery_start_date,
    on_change_delivery_start_date,
    fawry,
    on_change_fawry,
    years,
    on_change_years,
    down_payment,
    on_change_down_payment,
    delivery_date_list,
    onChangeMainCategory,
    onChangeMinPrice,
    onChangeMaxPrice,
  ] = ListingSidebarHook(false);

  const handleMainCategory = (e) => {
    setActiveBtn(e.target.id);
    onChangeMainCategory(e);
  };

  const roomsList = [
    {
      id: "1",
      value: "1",
    },
    {
      id: "2",
      value: "2",
    },
    {
      id: "3",
      value: "3",
    },
    {
      id: "4",
      value: "4",
    },
    {
      id: "5",
      value: "5",
    },
    {
      id: "0",
      value: "+5",
    },
  ];
  const bathroomsList = [
    {
      id: "1",
      value: "1",
    },
    {
      id: "2",
      value: "2",
    },
    {
      id: "3",
      value: "3",
    },
    {
      id: "4",
      value: "4",
    },
    {
      id: "5",
      value: "5",
    },
    {
      id: "0",
      value: "+5",
    },
  ];
  const yearsList = [
    {
      id: "1",
      value: "1",
    },
    {
      id: "2",
      value: "2",
    },
    {
      id: "3",
      value: "3",
    },
    {
      id: "4",
      value: "4",
    },
    {
      id: "5",
      value: "5",
    },
    {
      id: "0",
      value: "+5",
    },
  ];
  const finishingList = [
    {
      id: "1",
      value: "Red Bricks",
      label: "طوب احمر",
    },
    {
      id: "2",
      value: "Finishing text",
      label: " نص تشطيب",
    },
    {
      id: "3",
      value: "Super deluxe",
      label: "تشطيب كامل",
    },
  ];
  const payList = [
    {
      id: "1",
      value: "cash",
      label: "نقدا",
    },
    {
      id: "2",
      value: "installment",
      label: " تقسيط ",
    },
    {
      id: "3",
      value: "cash_and_insatllment",
      label: "نقدا وتقسيط",
    },
  ];
  const deliveryDateList = [
    {
      id: "1",
      value: "2025",
    },
    {
      id: "2",
      value: "2026",
    },
    {
      id: "3",
      value: "2027",
    },
    {
      id: "4",
      value: "2028",
    },
    {
      id: "5",
      value: "+2028",
    },
  ];

  return (
    <div className="filter-items">
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">الفئه</label>
        <ul className="d-flex align-items-center justify-content-between flex-wrap my-4">
          <li
            className={`py-2 px-5 border border-1 rounded pointer my-2 ${
              mainCategoryUrl === "residential" ? " bg-pri text-white" : ""
            }`}
            id="residential"
            onClick={(e) => handleMainCategory(e)}
          >
            سكنى
          </li>
          <li
            className={`py-2 px-5 border border-1 rounded pointer my-2 ${
              mainCategoryUrl === "Administrative" ? " bg-pri text-white" : ""
            }`}
            id="Administrative"
            onClick={(e) => handleMainCategory(e)}
          >
            ادارى
          </li>
          <li
            className={`py-2 px-5 border border-1 rounded pointer my-2 ${
              mainCategoryUrl === "commercial" ? " bg-pri text-white" : ""
            }`}
            id="commercial"
            onClick={(e) => handleMainCategory(e)}
          >
            تجارى
          </li>
        </ul>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">نوع العقار</label>
        <ul className="d-flex align-items-center justify-content-between flex-wrap my-4">
          {categoryList[0]?.map((item) => {
            return (
              <li
                key={item?.id}
                className={`w-100px p-1 h-100px border border-1 rounded pointer fs-18 text-center fit-flex my-1 user-select-none clicked-btn ${
                  categoryUrl === item?.id ? "bg-pri text-white" : ""
                }`}
                id={item?.id}
                onClick={(e) => onChangeCategoryId(e)}
              >
                {item?.label}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">القسم</label>
        <ul className="d-flex align-items-center my-4">
          <li
            className={`py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2 ${
              typeUrl === "sale" ? "bg-pri text-white" : ""
            }`}
            id="sale"
            onClick={(e) => onChangeType(e)}
          >
            بيع
          </li>
          <li
            className={`py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2 ${
              typeUrl === "rent" ? "bg-pri text-white" : ""
            }`}
            id="rent"
            onClick={(e) => onChangeType(e)}
          >
            ايجار
          </li>
        </ul>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">الدفع</label>
        <ul className="d-flex align-items-center justify-content-between flex-wrap my-4">
          {payList.map((item) => {
            return (
              <li
                key={item?.id}
                className={`py-2 px-5 border border-1 rounded pointer my-2 user-select-none clicked-btn ${
                  paying_url === item?.value ? "bg-pri text-white" : ""
                }`}
                onClick={(e) => onChangePaying(e)}
                value={item?.value}
                id={item?.value}
              >
                {item?.label}
              </li>
            );
          })}
        </ul>
      </div>
      {/* <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">الحاله</label>
        <ul className="d-flex align-items-center my-4">
          <li
            className="py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2"
            id="residential"
            onClick={(e) => handleMainCategory(e)}
          >
            من المطور
          </li>
          <li
            className="py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2"
            id="Administrative"
            onClick={(e) => handleMainCategory(e)}
          >
            اعادة بيع
          </li>
        </ul>
      </div> */}
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">نوع التشطيب</label>
        <ul className="d-flex align-items-center justify-content-between flex-wrap my-4">
          {finishingList.map((item) => {
            return (
              <li
                key={item?.id}
                className={`py-2 px-5 border border-1 rounded pointer my-2 user-select-none clicked-btn ${
                  finishingUrl === item?.value ? "bg-pri text-white" : ""
                }`}
                value={item?.value}
                onClick={() => onChangeFinishing(item?.value)}
              >
                {item?.label}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium"> المساحه بالمتر</label>
        <div className="form-group fit-flex">
          <input
            className="custom-input mx-2 bg-light-gray"
            value={minSpaceUrl}
            onChange={onChangeMinSpace}
            type="text"
            placeholder="من"
          />
          <input
            className="custom-input mx-2 bg-light-gray"
            value={maxSpaceUrl}
            onChange={onChangeMaxSpace}
            type="text"
            placeholder="الى"
          />
        </div>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">الغرف</label>
        <ul className="d-flex align-items-center flex-wrap my-4">
          {roomsList?.map((room) => {
            return (
              <li
                key={room?.id}
                className={`py-3 px-4 border border-1 rounded pointer mx-3 my-2 user-select-none clicked-btn ${
                  roomsUrl === room?.id ? "bg-pri text-white" : ""
                }`}
                onClick={(e) => onChangeRooms(e)}
                value={room?.id}
              >
                {room?.value}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">الحمامات</label>
        <ul className="d-flex align-items-center flex-wrap my-4">
          {bathroomsList?.map((bath) => {
            return (
              <li
                key={bath?.id}
                className={`py-3 px-4 border border-1 rounded pointer mx-3 my-2 user-select-none clicked-btn ${
                  bathroomsUrl === bath?.id ? "bg-pri text-white" : ""
                }`}
                onClick={(e) => onChangeBathrooms(e)}
                value={bath?.id}
              >
                {bath?.value}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">السعر (بالجنيه المصرى)</label>
        <div className="form-group fit-flex">
          <input
            className="custom-input mx-2 bg-light-gray"
            value={minPriceUrl}
            onChange={onChangeMinPrice}
            type="text"
            placeholder="من"
          />
          <input
            className="custom-input mx-2 bg-light-gray"
            value={maxPriceUrl}
            onChange={onChangeMaxPrice}
            type="text"
            placeholder="الى"
          />
        </div>
      </div>
      {/* <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">تمويل عقارى</label>
        <ul className="d-flex align-items-center my-4">
          <li
            className="py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2"
            id="residential"
            onClick={(e) => handleMainCategory(e)}
          >
            تصلح
          </li>
          <li
            className="py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2"
            id="Administrative"
            onClick={(e) => handleMainCategory(e)}
          >
            لا تصلح
          </li>
        </ul>
      </div> */}
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">التسليم</label>
        <ul className="d-flex align-items-center justify-content-between flex-wrap my-4">
          {/* <li
            className="py-2 px-3 border border-1 rounded pointer mx-2"
            id="residential"
            onClick={(e) => on_change_delivery_date(e)}
          >
            تم التسليم
          </li> */}
          {deliveryDateList.map((item) => {
            return (
              <li
                key={item?.id}
                className={`py-2 px-3 border border-1 rounded pointer mx-2 my-2 user-select-none clicked-btn ${
                  delivery_date_url === item?.value ? "bg-pri text-white" : ""
                }`}
                id={item?.value}
                value={item?.value}
                onClick={(e) => on_change_delivery_date(e)}
              >
                {item?.value}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">الفرش</label>
        <ul className="d-flex align-items-center flex-wrap my-4">
          <li
            className={`py-2 px-5 border border-1 rounded pointer my-2 w-50 text-center mx-2 ${
              furnishedUrl === "Furnished" ? "bg-pri text-white" : ""
            }`}
            value="Furnished"
            onClick={() => onChangeFurnished("Furnished")}
          >
            مفروش
          </li>
          <li
            className={`py-2 px-5 border border-1 rounded pointer w-50 text-center mx-2 ${
              furnishedUrl === "Unfurnished" ? "bg-pri text-white" : ""
            }`}
            value="Unfurnished"
            onClick={() => onChangeFurnished("Unfurnished")}
          >
            غير مفروش
          </li>
        </ul>
      </div>
      {/* <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">مقدم سعر الوحده</label>
        <div className="form-group fit-flex">
          <input
            className="custom-input mx-2 bg-light-gray"
            value={minSpaceUrl}
            onChange={onChangeMinSpace}
            type="text"
            placeholder="من"
          />
          <input
            className="custom-input mx-2 bg-light-gray"
            value={maxSpaceUrl}
            onChange={onChangeMaxSpace}
            type="text"
            placeholder="الى"
          />
        </div>
      </div> */}
      <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">سنوات القسط</label>
        <ul className="d-flex align-items-center flex-wrap my-4">
          {yearsList?.map((year) => {
            return (
              <li
                key={year?.id}
                className={`py-3 px-4 border border-1 rounded pointer mx-3 my-2 user-select-none clicked-btn ${
                  years_url === year?.id ? "bg-pri text-white" : ""
                }`}
                onClick={(e) => on_change_years(e)}
                value={year?.id}
                id={year?.id}
              >
                {year?.value}
              </li>
            );
          })}
        </ul>
      </div>
      {/* <div className="form-group p-3 border-bottom">
        <label className="fs-20 fw-medium">القسط الشهرى</label>
        <div className="form-group fit-flex">
          <input
            className="custom-input mx-2 bg-light-gray"
            value={minSpaceUrl}
            onChange={onChangeMinSpace}
            type="text"
            placeholder="من"
          />
          <input
            className="custom-input mx-2 bg-light-gray"
            value={maxSpaceUrl}
            onChange={onChangeMaxSpace}
            type="text"
            placeholder="الى"
          />
        </div>
      </div> */}
      <div className="form-group p-3 border-bottom d-flex align-items-center justify-content-evenly">
        <BtnComponent
          onClick={() => onSearchSidebar()}
          value={"عرض النتائح"}
          icon={<FontAwesomeIcon icon={faEye} />}
          st={"wd-fit"}
        />
        <BtnComponent
          onClick={() => Reset()}
          value={"Reset"}
          icon={<FontAwesomeIcon icon={faTrash} />}
          st={"wd-fit"}
        />
      </div>
    </div>
  );
};

export default FilterItems;
