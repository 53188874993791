import React from "react";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Landing from "../../components/GeneralSections/Landing/Landing";
import HomeSectionSkeleton from "../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import { useTranslation } from "react-i18next";
import CategoriesAnalysis from "../../components/Home/CategoriesAnalysis/CategoriesAnalysis.js";
import CustomHelmet from "../../components/Utility/SEO/CustomHelmet/CustomHelmet.js";
// lazy loading components
const Gates = React.lazy(() => import("../../components/Home/Gates/Gates.js"));
const HomeServices = React.lazy(() =>
  import("../../components/Home/HomeServices/HomeServices.js")
);
const Footer = React.lazy(() =>
  import("../../components/GeneralSections/Footer/Footer")
);
const Cta = React.lazy(() => import("../../components/Home/Cta/Cta"));
const WantToSell = React.lazy(() =>
  import("../../components/Home/WantToSell/WantToSell.js")
);
const PremiumUnits = React.lazy(() =>
  import("../../components/Home/PremiumUnits/PremiumUnits.js")
);
// const Clients = React.lazy(()=>import('../../components/Home/FeaturedClients/FeaturedClients'))
const HadayekMap = React.lazy(() =>
  import("../../components/Home/HadayekMap/HadayekMap.js")
);
const MobileAppSection = React.lazy(() =>
  import("../../components/Home/MobileAppSection/MobileAppSection.js")
);
// const HomeAbout = React.lazy(() =>
//   import("../../components/Home/HomeAbout/HomeAbout")
// );

const HomePage = () => {
  const [t] = useTranslation();
  return (
    <div className="home-page">
      <CustomHelmet
        title={t("homePage.meta.title")}
        description={t("SEO.meta.description")}
        keywords={t("SEO.meta.keywords")}
        canonical={window.location.href}
      />
      <Landing />
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <HadayekMap />
      </React.Suspense>
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <Gates />
      </React.Suspense>
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <Cta />
      </React.Suspense>
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <PremiumUnits />
      </React.Suspense>
      <CategoriesAnalysis />
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <HomeServices />
      </React.Suspense>
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <WantToSell />
      </React.Suspense>
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <MobileAppSection />
      </React.Suspense>
      <React.Suspense fallback={<HomeSectionSkeleton />}>
        <Footer />
      </React.Suspense>
      <ScrollToTopBtn />
    </div>
  );
};

export default HomePage;
